
/*Makes the entire page blue, otherwise the background starts 
to disappear when more than five words are added to the list*/
*{
  background-color: #6bb1fd;
  background-color: #007bfe;
}

button{
  background-color: white; /*prevents the universal function from changing them blue*/
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}

.App-header {
  min-height: 75vh; /*Reduced so that there wasn't much white space at the top.*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.letter-row{
  padding-bottom: 0.5rem;
}

.pressed-letters{
  position: absolute;
  left: 50%;
  padding: 1rem;
  transform: translateX(-50%); /*Helps align elements in the absolute position to the center*/
}

.submit-list{
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
}

#submit{
  width: 7rem;
  height: 3rem;
  padding: 10px;
  border-radius: .75rem;
  border: .25rem solid black;
  box-sizing: border-box;
  background-color:greenyellow;
  font-family:Helvetica, sans-serif;
  font-size:large;
  
}

#first-row{
  opacity: 50%;
  pointer-events: none;
}

#title{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
